import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-botox.jpg'

class BotoxMontclair extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query BotoxMontclairMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Botox Montclair, NJ | ${data.site.siteMetadata.title}`} description={`Dr. Sambataro, your expert aesthetic doctor here in Montclair, strives to stay up-to-date on the latest techniques and information concerning botox, providing safe and aesthetic solutions for patients wishing to restore a youthful confidence.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Botox Montclair, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>While in the past Botox carried a bit of a negative stigma, it has now emerged as arguably the most popular and accepted aesthetic procedures. Over 8 million individuals per year have taken part in botox since 2019, and have seen stunning results involving minimized fine lines, wrinkles, and creases.</p>

                                    <p>Botox utilizes a series of safe injections of botox, or botulinum toxin, in order to fight against the effects of aging skin. Dr. Sambataro, your expert aesthetic doctor here in Montclair, strives to stay up-to-date on the latest techniques and information concerning botox, providing safe and aesthetic solutions for patients wishing to restore a youthful confidence. For those in the Montclair area, Dr. Sambataro will even perform botox in a patient’s own home.</p>

                                    <h2>What is Botox used for?</h2>

                                    <p>Botox is used mostly on the face and neck to treat the negative effects that aging has on the skin. Botox can minimize forehead wrinkles and creases, crow’s feet, and even laugh lines.By immobilizing muscles, botox can also be preventative. The older we get, the more that our facial expressions and muscular movements take a toll on the face by giving way to deeper creases and wrinkles. Botox helps minimize facial movements while still looking natural, keeping wrinkles away!</p>

                                    <p>Botox can also be used for other issues that you may not have thought of. Botox can treat medical issues such as chronic migraines, uncontrollable blinking, involuntary muscle spasms, or excessive underarm sweating.</p>

                                    <h2>How does Botox work?</h2>

                                    <p>Botox works by injecting the botox solution into the desired regions previously agreed upon in a consultation with your Montclair doctor. The injections immobilize the muscle to minimize fine lines, creases, and wrinkles, providing a more aesthetic and youthful appearance.</p>

                                    <p>The botox procedure is brief, taking only a few minutes from start to finish. As no anesthesia is required for the procedure, doctors will instead opt for a a topical cream in order to numb the injection areas and avoid discomfort.</p>

                                    <p>Botox is often described as similar to a light bee sting, more mild than a typical shot.</p>

                                    <h2>What is the timeline for results and recovery with Botox?</h2>

                                    <p>The most important time of recovery for your Montclair botox is within the first 24 hours. Here, you must refrain from rubbing the injection site, so that the solution does not spread into other areas of the face.</p>

                                    <p>Additionally, your first night’s sleep is important in your recovery, and you’ll want to sleep elevated and on your back. This will keep the botox from spreading due to rubbing or pressing against your pillows. Finally, you should avoid difficult exercise for a few days. Allow yourself to rest and recover while the botox does its work.</p>

                                    <p>Desired results should be complete after 7 to 14 days. Once this time period is over, you should arrange one last check-in with your Montclair doctor to ensure everything has gone to plan. After this is complete, you can go and show off your youthful and aesthetic appearance.</p>

                                    <h2>What are common side effects of Botox?</h2>

                                    <p>Some side effects of botox include:</p>

                                    <ul>
                                        <li>muscular weakness or numbness</li>
                                        <li>drooping eyelid or smile</li>
                                        <li>excessive inflammation or bruising</li>
                                        <li>chronic headaches</li>
                                    </ul>

                                    <p>Most side effects only last for 24 to 48 hours, and are mild at worst. However, with a reputable doctor like Dr. Sambataro you can rest assured that your botox procedure will be safe and effective, and leave you with an appearance that you can be proud of.</p>

                                    <h2>In-Home Botox?</h2>

                                    <p>Dr.Sambataro provides in-home botox procedures for those in Montclair that prefer it.. We know that some have busy schedules, or others simply would rather have the convenience of an in-home procedure.</p>

                                    <h2>What is my first step for Botox?</h2>

                                    <p>The first step for botox with your Montclair doctor is scheduling a consultation at Optimum Health and Wellness. We will go over any questions or concerns you may have, describe the process in detail, and determine if botox is the right solution for you. <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>Call today</a>!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default BotoxMontclair